<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getEapList" method="post">
            <div class="con_top">
                <h2><span>■</span> EAP배정현황</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <select v-model="status" @change="getEapList()" class="font-16 w-120px h-40px pl-10">
                                <option value="">상태선택</option>
                                <option value="의뢰">의뢰</option>
                                <option value="센터신청">센터신청</option>
                                <option value="종료">종료</option>
                                <option value="확정">확정</option>
                            </select>
                            <input type="text" v-model="value" class="w-200px h-40px pd-00 pl-20" placeholder="기업명/고객명">
                            <!-- <a href="javascript:document.mfrm.submit();" class="btn_search ml-10">Search<span></span></a> -->
                            <a class="btn_search ml-10 pointer" @click="getEapList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>등록일</th>
                                <th>기업명</th>
                                <th>고객명</th>
                                <th>상담희망일정</th>
                                <th>상담유형</th>
                                <th>상담주제</th>
                                <th>배정센터</th>
                                <th>상태</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="9">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>등록일</th>
                                <th>기업명</th>
                                <th>고객명</th>
                                <th>상담희망일정</th>
                                <th>상담유형</th>
                                <th>상담주제</th>
                                <th>배정센터</th>
                                <th>상태</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of eapList" :key="index">
                                <td>{{item.createDate.slice(0,10)}}</td>
                                <td>{{item.companyName}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.eapHopeDate.slice(0,10)}}</td>
                                <td>{{item.typeName}}</td>
                                <td>{{item.subjectName}}</td>
                                <td>{{item.centerName}}</td>
                                <td>{{item.eapStatus}}</td>
                                <td><span class="underline pointer" v-if="item.eapStatus === '의뢰'" @click="modify(item.idx)">수정</span>
                                <span class="underline pointer" v-if="item.eapStatus === '센터신청'" @click="putPermission(item.idx, item.idxCrmCenter)">승인</span></td>
                            </tr>
                            <tr v-if="eapList.length === 0">
                                <td colspan="9">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        status: '',
        value: '',
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        eapList: [],
        loading:true,
    }),

    mounted() {

        if(sessionStorage.getItem('origin_eap_pageNum') !== 'undefined' && sessionStorage.getItem('origin_eap_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_eap_pageNum'))
        }
        if(sessionStorage.getItem('origin_eap_value') !== 'undefined' && sessionStorage.getItem('origin_eap_value') !== null) {
            this.value = sessionStorage.getItem('origin_eap_value')
        }
        if(sessionStorage.getItem('origin_eap_status') !== 'undefined' && sessionStorage.getItem('origin_eap_status') !== null) {
            this.status = sessionStorage.getItem('origin_eap_status')
        }

        this.getEapList()
    },

    methods: {

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getEapList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getEapList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getEapList()
            }
        },

        // EAP배정현황
        getEapList() {

            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                status: this.status      
            }

            sessionStorage.setItem('origin_eap_pageNum', this.pageNum)
            sessionStorage.setItem('origin_eap_value', this.value)
            sessionStorage.setItem('origin_eap_status', this.status)
            this.loading = true;
            this.axios.get('/api/v1/client/eap/list', { params: params })
                .then(res => {
                    if(res.data.err === 0) {
                        this.eapList = res.data.eapList
                        if (res.data.eapListSize) {
                                this.listLength = res.data.eapListSize
                            }
                            this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                            this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                            this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        this.eapList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },
        
        putPermission(idx, idxCrmCenter) {

            let params = new URLSearchParams();
            params.append('idxCrmClient', idx)
            params.append('idxCrmCenter', idxCrmCenter)

            this.axios.put('/api/v1/client/eap/permission', params)
                .then(res => {
                    if(res.data.err === 0) {
                        alert('승인 되었습니다.')
                        this.$router.go(this.$router.currentRoute)
                    } else {
                        this.eapList = []
                    }
                })
                .catch(err => {
                   console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // EAP 상담수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/center_eap_modify?idx=${idx}`)
        },
    }
}
</script>
<style lang="scss">
#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}
</style>